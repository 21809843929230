var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"news",staticStyle:{"background":"#F8F8F8"}},[(_vm.info.banners[0])?_c('v-img',{staticClass:"top-banner",attrs:{"src":_vm.info.banners[0].img}},[_c('v-layout',{staticStyle:{"position":"relative","text-align":"center"},attrs:{"align-center":"","fill-height":"","justify-center":""}},[_c('v-card',{staticClass:"text-center",staticStyle:{"background":"none !important","box-shadow":"none","clear":"both"}},[_c('v-card-text',[_c('div',{staticStyle:{"color":"#333","font-size":"48px"}}),_c('br')]),_c('v-card-actions')],1),_c('v-flex',{staticClass:"ctk-about-submenu",staticStyle:{"position":"absolute","bottom":"40px"}},[[_c('v-tabs',{attrs:{"light":"","grow":""},model:{value:(_vm.tabSubMenu),callback:function ($$v) {_vm.tabSubMenu=$$v},expression:"tabSubMenu"}},_vm._l((_vm.subMenu),function(menu,k){return _c('v-tab',{key:k},[_vm._v("\n                "+_vm._s(menu)+"\n              ")])}),1)]],2)],1)],1):_vm._e(),_c('v-container',{staticClass:"ctk-div",attrs:{"grid-list-md":"","text-center":"","id":"ctk_top"}},[_c('v-tabs-items',{model:{value:(_vm.tabSubMenu),callback:function ($$v) {_vm.tabSubMenu=$$v},expression:"tabSubMenu"}},[_c('v-tab-item',{key:"0"},[_c('v-container',{staticStyle:{"background":"#F8F8F8"},attrs:{"fluid":"","grid-list-sm":""}},[_vm._l((_vm.news.data),function(item,n){return [_c('v-layout',{key:n,staticStyle:{"background":"#FFF"},attrs:{"wrap":"","mb-12":"","pa-5":""}},[(n % 2 ==0)?[_c('v-flex',{attrs:{"xs12":"","sm4":"","md4":"","lg4":""}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto",attrs:{"elevation":hover ? 12 : 2}},[_c('a',{attrs:{"href":'/news/detail/' + item.id}},[_c('v-img',{attrs:{"src":item.attach.thumb,"aspect-ratio":"1","max-height":"330px"}})],1)])]}}],null,true)})],1),_c('v-flex',{key:n,staticClass:"text-left",staticStyle:{"position":"relative"},attrs:{"xs12":"","sm8":"","md8":"","lg8":"","pa-10":""}},[_c('a',{attrs:{"href":'/news/detail/' + item.id}},[_c('h3',{staticClass:"ctk-news-title"},[_vm._v(_vm._s(item.title))]),_c('section',{staticClass:"ctk-news-profile"},[_vm._v(_vm._s(item.profile))])]),_c('div',{staticClass:"ctk-news-date"},[_c('span',[_vm._v(_vm._s(_vm.publish)+"：")]),_c('span',[_vm._v(_vm._s(item.create_time.substring(0, 10)))])])])]:[_c('v-flex',{key:n,staticClass:"text-left",staticStyle:{"position":"relative"},attrs:{"xs12":"","sm8":"","md8":"","lg8":"","pa-10":""}},[_c('a',{attrs:{"href":'/news/detail/' + item.id}},[_c('h3',{staticClass:"ctk-news-title"},[_vm._v(_vm._s(item.title))]),_c('section',{staticClass:"ctk-news-profile"},[_vm._v(_vm._s(item.profile))])]),_c('div',{staticClass:"ctk-news-date"},[_c('span',[_vm._v(_vm._s(_vm.publish)+"：")]),_c('span',[_vm._v(_vm._s(item.create_time.substring(0, 10)))])])]),_c('v-flex',{attrs:{"xs12":"","sm4":"","md4":"","lg4":""}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto",attrs:{"elevation":hover ? 12 : 2}},[_c('a',{attrs:{"href":'/news/detail/' + item.id}},[_c('v-img',{attrs:{"src":item.attach.thumb,"aspect-ratio":"1","max-height":"330px"}})],1)])]}}],null,true)})],1)]],2)]}),[_c('div',{staticClass:"text-center"},[_c('v-pagination',{staticStyle:{"background":"#F8F8F8"},attrs:{"length":_vm.totalPage,"circle":""},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)]],2)],1),_c('v-tab-item',{key:"1"},[_c('v-container',{staticStyle:{"background":"#F8F8F8"},attrs:{"fluid":"","grid-list-sm":""}},[_vm._l((_vm.news.data),function(item,n){return [_c('v-layout',{key:n,staticStyle:{"background":"#FFF"},attrs:{"wrap":"","mb-12":"","pa-5":""}},[(n % 2 ==0)?[_c('v-flex',{attrs:{"xs12":"","sm4":"","md4":"","lg4":""}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto",attrs:{"elevation":hover ? 12 : 2}},[_c('a',{attrs:{"href":'/news/detail/' + item.id}},[_c('v-img',{attrs:{"src":item.attach.thumb,"aspect-ratio":"1","max-height":"330px"}})],1)])]}}],null,true)})],1),_c('v-flex',{key:n,staticClass:"text-left",staticStyle:{"position":"relative"},attrs:{"xs12":"","sm8":"","md8":"","lg8":"","pa-10":""}},[_c('a',{attrs:{"href":'/news/detail/' + item.id}},[_c('h3',{staticClass:"ctk-news-title"},[_vm._v(_vm._s(item.title))]),_c('section',{staticClass:"ctk-news-profile"},[_vm._v(_vm._s(item.profile))])]),_c('div',{staticClass:"ctk-news-date"},[_c('span',[_vm._v(_vm._s(_vm.publish)+"：")]),_c('span',[_vm._v(_vm._s(item.create_time.substring(0, 10)))])])])]:[_c('v-flex',{key:n,staticClass:"text-left",staticStyle:{"position":"relative"},attrs:{"xs12":"","sm8":"","md8":"","lg8":"","pa-10":""}},[_c('h3',[_vm._v(_vm._s(item.title))]),_c('section',{staticStyle:{"margin":"20px 0"}},[_vm._v(_vm._s(item.profile))]),_c('div',{staticStyle:{"position":"absolute","bottom":"20px"}},[_c('span',[_vm._v(_vm._s(_vm.publish)+"：")]),_c('span',[_vm._v(_vm._s(item.create_time.substring(0, 10)))])])]),_c('v-flex',{attrs:{"xs12":"","sm4":"","md4":"","lg4":""}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto",attrs:{"elevation":hover ? 12 : 2}},[_c('a',{attrs:{"href":'/news/detail/' + item.id}},[_c('v-img',{attrs:{"src":item.attach.thumb,"aspect-ratio":"1","max-height":"330px"}})],1)])]}}],null,true)})],1)]],2)]}),[_c('div',{staticClass:"text-center"},[_c('v-pagination',{staticStyle:{"background":"#F8F8F8"},attrs:{"length":_vm.totalPage,"circle":""},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)]],2)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }