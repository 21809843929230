<template>
  <section class="news" style="background: #F8F8F8;">
    <v-img
      v-if="info.banners[0]"
      :src="info.banners[0].img"
      class="top-banner"
      >
        <v-layout
          align-center
          fill-height
          justify-center
          style="position: relative; text-align: center;"
        >
          <v-card class="text-center" style="background:none !important; box-shadow: none; clear: both;">
            <v-card-text>
              <div style="color:#333; font-size: 48px;"></div> <br/>
            </v-card-text>
            <v-card-actions>
            </v-card-actions>
          </v-card>
          <v-flex style="position: absolute; bottom: 40px;" class="ctk-about-submenu">
            <template>
              <v-tabs
                v-model="tabSubMenu"
                light
                grow
              >
                <v-tab
                  v-for="(menu, k) in subMenu"
                  :key="k"
                >
                  {{menu}}
                </v-tab>
              </v-tabs>
            </template>
          </v-flex>
        </v-layout>
      </v-img>
    <v-container  grid-list-md text-center class="ctk-div" id="ctk_top">
      <v-tabs-items v-model="tabSubMenu">
        <!--公司新闻-->
        <v-tab-item key="0">
          <v-container
            fluid
            grid-list-sm
            style="background: #F8F8F8;"
          >
            <template v-for="(item, n) in news.data">
              <v-layout wrap :key="n" style="background: #FFF;" mb-12 pa-5>
                <template v-if="n % 2 ==0">
                  <v-flex xs12 sm4 md4 lg4>
                    <v-hover v-slot:default="{ hover }">
                      <v-card
                        :elevation="hover ? 12 : 2"
                        class="mx-auto"
                      >
                        <a :href="'/news/detail/' + item.id">
                        <v-img
                          :src="item.attach.thumb"
                          aspect-ratio="1"
                          max-height="330px"
                        ></v-img>
                        </a>
                      </v-card>
                    </v-hover>
                  </v-flex>
                  <v-flex xs12 sm8 md8 lg8 pa-10 class="text-left" style="position: relative;" :key="n">
                    <a :href="'/news/detail/' + item.id">
                    <h3 class="ctk-news-title">{{item.title}}</h3>
                    <section class="ctk-news-profile">{{item.profile}}</section>
                    </a>
                    <div class="ctk-news-date">
                      <span>{{publish}}：</span><span>{{item.create_time.substring(0, 10)}}</span>
                    </div>
                  </v-flex>
                </template>
                <template v-else>
                  <v-flex xs12 sm8 md8 lg8 pa-10 class="text-left" style="position: relative;" :key="n">
                    <a :href="'/news/detail/' + item.id">
                    <h3 class="ctk-news-title">{{item.title}}</h3>
                    <section class="ctk-news-profile">{{item.profile}}</section>
                    </a>
                    <div class="ctk-news-date">
                      <span>{{publish}}：</span><span>{{item.create_time.substring(0, 10)}}</span>
                    </div>
                  </v-flex>
                  <v-flex xs12 sm4 md4 lg4>
                    <v-hover v-slot:default="{ hover }">
                      <v-card
                        :elevation="hover ? 12 : 2"
                        class="mx-auto"
                      >
                        <a :href="'/news/detail/' + item.id">
                          <v-img
                            :src="item.attach.thumb"
                            aspect-ratio="1"
                            max-height="330px"
                          ></v-img>
                        </a>
                      </v-card>
                    </v-hover>
                  </v-flex>
                </template>
              </v-layout>
            </template>
            <template>
              <div class="text-center">
                <v-pagination
                  v-model="page"
                  :length="totalPage"
                  circle
                  style="background: #F8F8F8;"
                ></v-pagination>
              </div>
            </template>
          </v-container>
        </v-tab-item>
        <!--行业动态-->
        <v-tab-item key="1">
          <v-container
            fluid
            grid-list-sm
            style="background: #F8F8F8;"
          >
            <template v-for="(item, n) in news.data">
              <v-layout wrap :key="n" style="background: #FFF;" mb-12 pa-5>
                <template v-if="n % 2 ==0">
                  <v-flex xs12 sm4 md4 lg4>
                    <v-hover v-slot:default="{ hover }">
                      <v-card
                        :elevation="hover ? 12 : 2"
                        class="mx-auto"
                      >
                        <a :href="'/news/detail/' + item.id">
                          <v-img
                            :src="item.attach.thumb"
                            aspect-ratio="1"
                            max-height="330px"
                          ></v-img>
                        </a>
                      </v-card>
                    </v-hover>
                  </v-flex>
                  <v-flex xs12 sm8 md8 lg8 pa-10 class="text-left" style="position: relative;" :key="n">
                    <a :href="'/news/detail/' + item.id">
                    <h3 class="ctk-news-title">{{item.title}}</h3>
                    <section class="ctk-news-profile">{{item.profile}}</section>
                    </a>
                    <div class="ctk-news-date">
                      <span>{{publish}}：</span><span>{{item.create_time.substring(0, 10)}}</span>
                    </div>
                  </v-flex>
                </template>
                <template v-else>
                  <v-flex xs12 sm8 md8 lg8 pa-10 class="text-left" style="position: relative;" :key="n">
                    <h3>{{item.title}}</h3>
                    <section style="margin: 20px 0;">{{item.profile}}</section>
                    <div style="position:absolute; bottom: 20px;">
                      <span>{{publish}}：</span><span>{{item.create_time.substring(0, 10)}}</span>
                    </div>
                  </v-flex>
                  <v-flex xs12 sm4 md4 lg4>
                    <v-hover v-slot:default="{ hover }">
                      <v-card
                        :elevation="hover ? 12 : 2"
                        class="mx-auto"
                      >
                        <a :href="'/news/detail/' + item.id">
                          <v-img
                            :src="item.attach.thumb"
                            aspect-ratio="1"
                            max-height="330px"
                          ></v-img>
                        </a>
                      </v-card>
                    </v-hover>
                  </v-flex>
                </template>
              </v-layout>
            </template>
            <template>
              <div class="text-center">
                <v-pagination
                  v-model="page"
                  :length="totalPage"
                  circle
                  style="background: #F8F8F8;"
                ></v-pagination>
              </div>
            </template>
          </v-container>
        </v-tab-item>
      </v-tabs-items>
    </v-container>
  </section>
</template>
<script>
export default {
  data: () => ({
    pageId: 4,
    tabSubMenu: null,
    introduce: null,
    introduceText: null,
    subMenu: null,
    news: {
      'introduction': null
    },
    totalPage: 1,
    page: 1
  }),
  created () {
    document.querySelector('#menu_news').classList.add('v-btn--active')
    this.getPageData()
    if (this.$route.params.type === '3') {
      this.tabSubMenu = 1
    }
  },
  watch: {
    '$store.getters.companyInfo': {
      handler: function (val, oldVal) {
        this.info.banners = val.banner[this.pageId]
      },
      deep: true
    },
    params: {
      handler: function (val, oldVal) {
        let type = 2
        let page = val.page
        if (val.tabSubMenu === 1) {
          type = 3
        }
        if (val.tabSubMenu !== oldVal.tabSubMenu) {
          page = 1
        }
        document.querySelector('#ctk_top').scrollIntoView(true)
        this.getNews(type, page)
      },
      deep: true
    }
  },
  computed: {
    info () {
      let banners = {}
      banners[this.pageId] = []
      if (this.$store.getters.companyInfo.banner) {
        banners = this.$store.getters.companyInfo.banner[this.pageId]
      }
      return {
        banners: banners
      }
    },
    params () {
      const { tabSubMenu, page } = this
      return {
        tabSubMenu,
        page
      }
    }
  },
  methods: {
    getPageData () {
      this.introduce = this.$t('news.introduce')
      this.subMenu = this.$t('news.menu')
      this.detail = this.$t('detail')
      this.publish = this.$t('publish')
    },
    getNews (type = 2, page = 1) {
      this.totalPage = 1
      this.https.get('news', { type: type, page: page }).then(response => {
        if (response.code === 0) {
          this.news = response.data
          this.totalPage = this.news.last_page
          this.page = page
        }
      })
    }
  }
}
</script>
